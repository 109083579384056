* {
    font-family: 'Inter', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-size: 16px;
    color: #333;
}

h2 {
    color: #12cf19;
}


/* Chat */
.chat-sample-cell {
    padding: 5px;
}
.chat-bot {
    padding-top: 15px;
    padding-bottom: 20px;
    border-bottom: 1px solid #dee1e3;
}

.chat-user {
    padding-top: 15px;
    padding-bottom: 20px;
    background-color: white;
    border: 1px solid #dee1e3;
}

.chat-block {
    display: flex;
    max-width: 800px;
}

.chat-avatar {
    flex: 1;
    width: 10%;
}
.chat-content {
    flex: 9;
    width: 90%;
    text-align: justify;
}
.chat-bottom {
    width: 100%;
    display: flex;
    max-width: 800px;
}
.chat-input {
    flex: 9;
    width: 90%;
    padding-right: 5px;
}
.chat-button {
    flex: 1;
    width: 10%;
    min-width: fit-content;
}

/* Chat new */
.app-container {
    display: flex;
    height: calc(100vh - 185px);
    font-size: 20px;
}

.chat-n-section {
    flex: 0 0 30%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-left: 5px;
    transition: flex 0.3s ease, margin 0.3s ease;
}

/* Khi editor bị đóng, chat section sẽ mở rộng ra 80% */
.chat-n-section.expanded {
    flex: 0 0 60%;
    margin: 0 auto;
    /* Căn giữa */
}

@media (max-width: 768px) {
    .chat-n-section.expanded {
        flex: 0 0 100%;
    }
}

.chat-n-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.chat-n-header h2 {
    font-size: 20px;
    font-weight: 600;
    /* Tăng độ dày của font */
}

.show-btn {
    background: none;
    border: none;
    color: #6610f2;
    font-size: 20px;
    cursor: pointer;
}

.show-btn:hover {
    color: #6f42c1;
}

.chat-n-content {
    flex: 1;
    margin-top: 10px;
    overflow-y: auto;
    padding: 10px;
    border-radius: 8px;
    height: calc(100vh - 180px);
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 18px;
    /* Giảm kích thước font để dễ đọc hơn */
    color: #3c3c3c;
}

.message-n-user {
    align-self: flex-end;
    background-color: #d6ebdb;
    color: #000;
    padding: 8px 12px;
    border-radius: 15px 15px 0 15px;
    max-width: 80%;
    font-size: 16px;
    line-height: 1.4;
    font-weight: 500;
}

.message-n-bot-section {
    display: flex;
    align-items: start; /* Đảm bảo các phần tử được căn giữa theo trục ngang */
    gap: 10px; /* Khoảng cách giữa avatar và message */
}

.message-n-bot-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.message-n-bot {
    align-self: flex-start;
    background-color: #e0d7d7;
    color: #333;
    padding: 8px 12px;
    padding-left: 25px;
    padding-bottom: 0px;
    border-radius: 0px 15px 15px 15px;
    max-width: 80%;
    font-size: 16px;
    line-height: 1.4;
    font-weight: 500;
}

.loading-dots {
    display: inline-block;
    padding-bottom: 10px;
}

.loading-dots span {
    display: inline-block;
    font-size: 24px;
    line-height: 1;
    animation: blink 1.4s infinite both;
}

.loading-dots span:nth-child(2) {
    animation-delay: 0.4s;
}

.loading-dots span:nth-child(3) {
    animation-delay: 0.8s;
}

@keyframes blink {
    0%,
    20%,
    50%,
    80%,
    100% {
        opacity: 1;
    }

    40% {
        opacity: 0;
    }

    60% {
        opacity: 0.5;
    }
}


.chat-n-input {
    display: flex;
    padding: 10px 0;
}

/* Căn giữa input và nút send trong chế độ mở rộng */
.chat-n-section.expanded .chat-input {
    justify-content: center;
}

.chat-n-input input {
    flex: 1;
    max-width: 100%;
    /* Giới hạn chiều rộng input */
    padding: 10px;
    font-size: 18px;
    border: 1px solid #ddd;
    border-radius: 4px 0 0 4px;
    outline: none;
    transition: border-color 0.2s;
}

.chat-n-input input:focus {
    border-color: #2eb85c;
}

.chat-n-input button {
    padding: 10px 20px;
    background-color: #2eb85c;
    color: white;
    border: none;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    font-size: 15px;
    font-weight: 500;
}

.chat-n-input button:hover {
    background-color: #1b9e3e;
}

.editor-section {
    flex: 1;
    padding: 20px;
    background-color: #ffffff;
    position: relative;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.5;
}

.editor-section h2 {
    padding-left: 10px;
    font-size: 20px;
    font-weight: 600;
}

.close-btn {
    position: absolute;
    top: 10px;
    left: 10px;
    border: none;
    background: none;
    color: #ff5e5e;
    font-size: 20px;
    cursor: pointer;
}


.copy-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    color: #4caf50;
    font-size: 20px;
    cursor: pointer;
}

.editor {
    width: 98%;
    height: calc(100vh - 80px);
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-size: 16px;
    resize: none;
}


/* Payment */
.payment-card {
    border: none;
    margin-top: 20px;
    padding: 20px;
}

.payment-option {
    text-align: center;
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    margin: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
}

.payment-option:hover {
    transform: translateY(-5px);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.payment-vip-highlight {
    border: 1px solid green;
    background: #f0fff4;
}

.payment-header {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.payment-header.payment-vip {
    color: rgb(239, 20, 110);
}

.payment-price {
    margin: 10px 0;
}

.price-original {
    font-size: 0.9rem;
    text-decoration: line-through;
    color: #888;
}

.price-discount {
    font-size: 1.2rem;
    font-weight: bold;
    color: #000;
}

.payment-discount {
    font-size: 0.9rem;
    color: #007bff;
    margin-bottom: 10px;
}

.payment-details {
    font-size: 0.9rem;
    color: #555;
    margin-top: 30px;
}

.icon-green {
    color: green;
    margin-right: 5px;
}

.icon-red {
    color: red;
    margin-right: 5px;
}

.payment-button {
    margin-top: 10px;
    padding: 8px 16px;
    color: #ffffff;
}

.payment-button:hover {
    color: #ffffff;
}



/* Home */

.banner-container {
    background-image: url("../assets/images/banner.png");
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left; /* Đảm bảo text căn giữa */
    min-height: 420px;
    background-color: #f8f9fa; /* Tùy chỉnh màu nền nếu cần */
    padding: 20px;
}

.banner-text {
    color: #ffffff; /* Tông màu văn bản phù hợp */
    margin: 0 auto;
    align-items: center;
}

.banner-stats {
    display: flex;
    justify-content: left;
    gap: 20px; /* Khoảng cách giữa các mục */
    margin-top: 20px;
}

.banner-stat-item {
    text-align: left; /* Căn giữa từng mục */
    padding: 10px;
    font-size: 1rem;
    font-weight: 500;
}

.banner-stat-item h3 {
    font-size: 2rem;
    margin: 0;
    color: #28a745; /* Màu xanh lá nổi bật */
}

/* Section Container */
.investment-container {
    text-align: center;
    background-color: #f3f7f5;
    padding: 50px 20px;
}

/* Heading */
.investment-heading {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 30px;
    color: #28a745;
}

/* Row Content */
.investment-row {
    align-items: center; /* Căn giữa nội dung theo chiều dọc */
    justify-content: center;
    gap: 10px; /* Khoảng cách nhỏ hơn giữa text và ảnh */
}

/* Text Content */
.investment-text {
    font-size: 1.125rem;
    text-align: left;
    color: #34495e;
    line-height: 1.8;
    padding: 10px;
    display: flex;
    justify-content: center;  /* Căn giữa theo chiều ngang */
    align-items: center;      /* Căn giữa theo chiều dọc */
    height: 400px;             /* Đảm bảo chiếm toàn bộ chiều cao của container */
}

/* List Style */
.investment-list {
    padding: 0;
    list-style: none;
    margin: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 450px; /* Tăng giới hạn chiều rộng */
}

.investment-list li {
    margin-bottom: 20px;
}

.investment-list h5 {
    font-size: 1.125rem;
    font-weight: bold;
    margin-bottom: 5px;
    color: #27ae60;
}

.investment-list i {
    font-size: 0.95rem;
    color: #7f8c8d;
}

/* Image Content */
.investment-image-container {
    align-items: center;
    justify-content: center;
}

.investment-image {
    max-width: 100%;
    height: auto; /* Đảm bảo tỷ lệ ảnh không bị vỡ */
    width: auto;
    max-height: 400px; /* Giới hạn chiều cao */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Hiệu ứng nổi */
}

.investment-image:hover {
    transform: scale(1.03);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
}

/* Link Styling */
.link-no-decorator {
    color: inherit;
    text-decoration: none;
    transition: color 0.3s ease;
}

.link-no-decorator:hover {
    color: #0056b3;
    text-decoration: underline;
}

/* Tags */
.tag {
    background-color: #ebedef;
    border-radius: 10px;
    padding: 8px 15px;
    margin: 5px;
    font-size: 0.9rem;
    font-weight: 500;
}

/* Footer */
.footer-header {
    font-weight: bold;
    margin-bottom: 10px;
}

.footer-item {
    color: inherit;
    text-decoration: none;
    font-size: 0.85rem;
    transition: color 0.3s ease;
}

.footer-item:hover {
    color: #0056b3;
}

/* Cards */
.card-container {
    padding: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border: none;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
}

.card-container:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.card-title {
    font-size: 1.25rem;
    font-weight: bold;
    margin: 15px 0;
    color: #333333;
}

.card-text {
    font-size: 0.95rem;
    color: #555555;
    line-height: 1.6;
    text-align: justify;
}

.card-image {
    max-height: 120px;
    max-width: 120px;
    margin: 10px auto; /* Căn giữa */
    display: block; /* Đảm bảo ảnh là block để căn giữa */
    border-radius: 50%;
    background-color: #f7f7f7;
    padding: 10px;
}



/* CMS */

.table-row-expanded {
    background-color: #f9f9f9;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.page-button {
    margin: 0 5px;
    padding: 5px 10px;
    border: 1px solid #ccc;
    background: #f9f9f9;
    cursor: pointer;
}

.page-button.active {
    background: #007bff;
    color: white;
    border-color: #007bff;
}